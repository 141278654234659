<template>
  <div class="container">
    <div v-if="showHeader" class="head-item">
      <img class="close-btn" src="../../assets/images/pc/close-icon.png" @click="$router.go(-1);" />
      <div class="title">{{ title }}</div>
    </div>
    <div class="content" :class="showHeader ? 'has-header' : ''">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>

import { protocoDetail } from '@/api/index'

export default {
  name: 'Agreement',
  data () {
    return {
      id: '',
      title: '',
      content: '',
      userAgent: ''
    }
  },
  computed: {
    showHeader() {
      if (this.userAgent.indexOf('OYQAgent') > -1) {
        return false
      }
      return true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.id = to.query.id || ''
      if (to.query.id) {
        vm.handleGetPageData()
      }
    })
  },
  mounted () {
    this.userAgent = navigator.userAgent
  },
  methods: {
    handleGetPageData () {
      protocoDetail({
        id: this.id
      }).then(res => {
        console.log(res)
        this.title = res.info.name
        document.title = res.info.name
        this.content = res.info.content
      })
    }
  }
}
</script>

<style lang="less" scoped>
.head-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100rem;
  text-align: center;
  line-height: 100rem;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, .2);
  .close-btn {
    position: absolute;
    right: 35rem;
    top: 35rem;
    width: 30rem;
    height: 30rem;
  }
  .title {
    padding: 0 70rem;
    font-size: 26rem;
  }
}
.content {
  padding: 20rem 20rem 20rem;
  font-size: 26rem;
  &.has-header {
    padding-top: 120rem;
  }
}
</style>
